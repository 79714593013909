import { Box, Flex, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'

const Prize = ({ prize, mininal, league, countUserSets }) => {
  
  if  (!prize) {
    return null
  }
  const date = dayjs(prize.date)

  return (
    <Box>
      {!mininal && (
        countUserSets === undefined ? (
        <Flex
          fontSize="14px"
          fontWeight="600"
          textTransform="uppercase"
        >
          <Text
            color="var(--chakra-colors-white)"
          >{date.format('ddd MM/DD')}</Text>
          {league && <>
            <Text
              mx="10px"
              color="var(--chakra-colors-white80)"
            >·</Text>
            <Text
              color="var(--chakra-colors-white80)"
            >{league}</Text>
          </>}
        </Flex>) : (
          <Flex
          fontSize="14px"
          fontWeight="600"
          textTransform="uppercase"
        >
          <Text
            color="var(--chakra-colors-white)"
          >{date ? date.format('ddd (M/D)') : ""} {league}</Text>
          {league && <>
            <Text
              mx="10px"
              color="var(--chakra-colors-white80)"
            >·</Text>
            <Text
              color="var(--chakra-colors-white80)"
            >{countUserSets} Hands</Text>
          </>}
        </Flex>
        )
      )}
      <Box 
        my="10px"
        h="200px"
        bg={`url(${prize.image})`}
        backgroundPosition="center center"
        backgroundSize="cover"
        borderRadius="12px"
        position="relative"
      >
        <Flex
          position="absolute"
          w="79px"
          h="79px"
          borderRadius="50%"
          bg="var(--chakra-colors-blue)"
          color="white"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          bottom="12px"
          right="12px"
        >
          <Text
            fontSize="20px"
            fontWeight="700"
            color="var(--chakra-colors-white)"
            lineHeight="1.2"
          >${prize.price}</Text>
          <Text
            fontSize="14px"
            fontWeight="700"
            color="var(--chakra-colors-white80)"
            lineHeight="1"
          >value</Text>
        </Flex>
      </Box>
      <Text
        fontSize="18px"
        fontWeight="700"
        color="white"
        align={mininal ? 'center' : 'left'}
      >{prize.description}</Text>
      {prize.email && (
        <Text
          color="var(--chakra-colors-blue)"
          textTransform="uppercase"
          fontSize="14px"
          fontWeight="600"
          mt="5px"
        >
          claimed by {prize.email}
        </Text>
      )}
    </Box>
  )
  }

export default Prize

// import { Box, Flex, Text } from '@chakra-ui/react'
// import dayjs from 'dayjs'

// const Prize = ({ claimed, prize, mininal, league, countUserSets }) => {
  
//   if  (!prize) {
//     return null
//   }
//   const date = dayjs(prize.date)


//   return (
//     <Box>
//       {!mininal && (
//         countUserSets === undefined ? (
//         <Flex
//           fontSize="14px"
//           fontWeight="600"
//           textTransform="uppercase"
//         >
//           <Text
//             color="var(--chakra-colors-white)"
//           >{date.format('ddd M/DD')}</Text>
//           {(prize.league || league) && <>
//             <Text
//               mx="10px"
//               color="var(--chakra-colors-white80)"
//             >·</Text>
//             <Text
//               color="var(--chakra-colors-white80)"
//             >{prize.league || league}</Text>
//           </>}
//         </Flex>) : (
//           <Flex
//           fontSize="14px"
//           fontWeight="600"
//           textTransform="uppercase"
//         >
//           <Text
//             color="var(--chakra-colors-white)"
//           >{date ? date.format('ddd (M/D)') : ""} {league}</Text>
//           {league && <>
//             <Text
//               mx="10px"
//               color="var(--chakra-colors-white80)"
//             >·</Text>
//             <Text
//               color="var(--chakra-colors-white80)"
//             >{countUserSets} Hands</Text>
//           </>}
//         </Flex>
//         )
//       )}
//       <Box 
//         my="10px"
//         h={claimed ? '345px' : '200px'}
//         bg={`url(${prize.image})`}
//         backgroundPosition="center center"
//         backgroundSize="cover"
//         borderRadius="12px"
//         position="relative"
//       >
//         {!claimed && (<Flex
//           position="absolute"
//           w="79px"
//           h="79px"
//           borderRadius="50%"
//           bg="var(--chakra-colors-blue)"
//           color="white"
//           justifyContent="center"
//           alignItems="center"
//           flexDirection="column"
//           bottom="12px"
//           right="12px"
//         >
//           <Text
//             fontSize="20px"
//             fontWeight="700"
//             color="var(--chakra-colors-white)"
//             lineHeight="1.2"
//           >${prize.price}</Text>
//           <Text
//             fontSize="14px"
//             fontWeight="700"
//             color="var(--chakra-colors-white80)"
//             lineHeight="1"
//           >value</Text>
//         </Flex>)}
//       </Box>
//       <Text
//         fontSize="18px"
//         fontWeight="700"
//         color="white"
//         align={mininal ? 'center' : 'left'}
//       >{prize.description}</Text>
//       {claimed && (
//         <Text
//             color="var(--chakra-colors-blue)"
//             textTransform="uppercase"
//             fontSize="14px"
//             fontWeight="600"
//             mt="5px"
//           >
//             WON BY {prize.username}
//         </Text>
//       )}
      
//     </Box>
//   )
//   }

// export default Prize